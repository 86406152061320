@import "_includes/indigo-pink.css";
:root {
  --hls-color-elevation: 0, 0, 0;
}

.hls-elevation-1 {
  box-shadow: 0 2px 6px 0 rgba(var(--hls-color-elevation), 0.14), 0 1px 2px 0 rgba(var(--hls-color-elevation), 0.08), 0 0 1px 0 rgba(var(--hls-color-elevation), 0.06), 0 0 0 0 rgba(var(--hls-color-elevation), 0.04);
}

.hls-elevation-2 {
  box-shadow: 0 8px 17px 0 rgba(var(--hls-color-elevation), 0.1), 0 2px 5px 0 rgba(var(--hls-color-elevation), 0.06), 0 1px 2px 0 rgba(var(--hls-color-elevation), 0.04), 0 0 1px 0 rgba(var(--hls-color-elevation), 0.03);
}

.hls-elevation-3 {
  box-shadow: 0 10px 20px 0 rgba(var(--hls-color-elevation), 0.25), 0 2px 6px 0 rgba(var(--hls-color-elevation), 0.3);
}

html {
  box-sizing: border-box;
  text-size-adjust: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a,
button,
label,
input {
  -webkit-tap-highlight-color: transparent;
}

a {
  -webkit-text-decoration-skip: objects;
  color: inherit;
  text-decoration: none;
}
a:active, a:hover {
  outline-width: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  font-weight: 300;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
dl,
dd,
ol,
ul,
form,
fieldset,
legend,
table,
th,
td,
caption,
hr {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

ol,
ul {
  list-style: none;
}

/**
 * helper function to convert an rem value into a pixel unit
 * This is base on the change font-size on the html to 62,5% (= 10px).
 */
/* for icons, texts and links */
/* buttons */
/* buttons */
@keyframes loadingPulseAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;
  }
}
/* for icons, texts and links */
/* buttons */
/* buttons */
body,
input,
select,
button, a:hover, a, a:active, a:visited, a:focus, .color-success, .color-warning, .color-error, .color-muted, .text-xs, .text-sm {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.3;
}

body,
input,
select,
button, a:hover, a, a:active, a:visited, a:focus, .color-success, .color-warning, .color-error, .color-muted, .text-xs, .text-sm {
  color: #000a12;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;
}

.text-sm {
  font-size: 12px;
  line-height: 16px;
}

.text-xs {
  font-size: 10px;
  line-height: 13px;
}

.color-muted {
  color: gray;
}

.color-error {
  color: #d63339;
}

.color-warning {
  color: #fa9600;
}

.color-success {
  color: #64be28;
}

h4, h3, h2, h1 {
  margin-top: 16px;
  color: #000a12;
  font-family: Roboto, sans-serif;
  font-weight: 700 !important;
}

h1 {
  margin-top: 24px;
  font-size: 22px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 14px;
}

h4 {
  color: #000a12;
  font-size: 12px;
}

a:hover, a, a:active, a:visited, a:focus {
  color: #005aa0;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #2277BC;
  text-decoration: underline;
}

:root {
  --hls-color-elevation: 0, 0, 0;
}

.hls-elevation-1 {
  box-shadow: 0 2px 6px 0 rgba(var(--hls-color-elevation), 0.14), 0 1px 2px 0 rgba(var(--hls-color-elevation), 0.08), 0 0 1px 0 rgba(var(--hls-color-elevation), 0.06), 0 0 0 0 rgba(var(--hls-color-elevation), 0.04);
}

.hls-elevation-2 {
  box-shadow: 0 8px 17px 0 rgba(var(--hls-color-elevation), 0.1), 0 2px 5px 0 rgba(var(--hls-color-elevation), 0.06), 0 1px 2px 0 rgba(var(--hls-color-elevation), 0.04), 0 0 1px 0 rgba(var(--hls-color-elevation), 0.03);
}

.hls-elevation-3 {
  box-shadow: 0 10px 20px 0 rgba(var(--hls-color-elevation), 0.25), 0 2px 6px 0 rgba(var(--hls-color-elevation), 0.3);
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.underline {
  font-style: italic;
}

body {
  background-color: #F8F9FB;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
select,
button {
  font-feature-settings: "kern", "liga";
}

p {
  margin-bottom: 1.6rem;
}

.hls-tab-host {
  background: inherit;
}
.hls-tab-host .hls-tab-headers {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  min-height: 50px;
  border-bottom: 1px solid #E5E5E5;
  background-color: #FFFFFF;
  line-height: 50px;
  vertical-align: middle;
}
.hls-tab-host .hls-tab-headers .hls-tab-header {
  min-width: 160px;
  padding: 0 16px;
  border-bottom: 3px solid transparent;
  color: #808080;
  text-align: center;
  cursor: pointer;
}
.hls-tab-host .hls-tab-headers .hls-tab-header:hover:not(.hls-tab-host .hls-tab-headers .hls-tab-header--active) {
  border-color: #1C8DC6;
  color: #2277BC;
}
.hls-tab-host .hls-tab-headers .hls-tab-header--active {
  border-color: #005AA0;
  background-color: #E1F0F6;
  color: #005AA0;
}

.hls-tab {
  display: none;
}
.hls-tab--active {
  display: block;
}

/**
 * helper function to convert an rem value into a pixel unit
 * This is base on the change font-size on the html to 62,5% (= 10px).
 */
/* for icons, texts and links */
/* buttons */
/* buttons */
@keyframes loadingPulseAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.75;
  }
}
/* for icons, texts and links */
/* buttons */
/* buttons */
a, a:active, a:visited, a:focus, a:hover, .color-error, .color-warning, .color-success, .color-muted, .text-sm, .text-xs, body,
input,
select,
button {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.3;
}

a, a:active, a:visited, a:focus, a:hover, .color-error, .color-warning, .color-success, .color-muted, .text-sm, .text-xs, body,
input,
select,
button {
  color: #000a12;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 18px;
}

.text-sm {
  font-size: 12px;
  line-height: 16px;
}

.text-xs {
  font-size: 10px;
  line-height: 13px;
}

.color-muted {
  color: gray;
}

.color-error {
  color: #d63339;
}

.color-warning {
  color: #fa9600;
}

.color-success {
  color: #64be28;
}

h1, h2, h3, h4 {
  margin-top: 16px;
  color: #000a12;
  font-family: Roboto, sans-serif;
  font-weight: 700 !important;
}

h1 {
  margin-top: 24px;
  font-size: 22px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 14px;
}

h4 {
  color: #000a12;
  font-size: 12px;
}

a, a:active, a:visited, a:focus, a:hover {
  color: #005aa0;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #2277BC;
  text-decoration: underline;
}

:root {
  --hls-color-elevation: 0, 0, 0;
}

.hls-elevation-1 {
  box-shadow: 0 2px 6px 0 rgba(var(--hls-color-elevation), 0.14), 0 1px 2px 0 rgba(var(--hls-color-elevation), 0.08), 0 0 1px 0 rgba(var(--hls-color-elevation), 0.06), 0 0 0 0 rgba(var(--hls-color-elevation), 0.04);
}

.hls-elevation-2 {
  box-shadow: 0 8px 17px 0 rgba(var(--hls-color-elevation), 0.1), 0 2px 5px 0 rgba(var(--hls-color-elevation), 0.06), 0 1px 2px 0 rgba(var(--hls-color-elevation), 0.04), 0 0 1px 0 rgba(var(--hls-color-elevation), 0.03);
}

.hls-elevation-3 {
  box-shadow: 0 10px 20px 0 rgba(var(--hls-color-elevation), 0.25), 0 2px 6px 0 rgba(var(--hls-color-elevation), 0.3);
}

.width-0 {
  width: 0;
}

.width-5 {
  width: 5;
}

.width-10 {
  width: 10;
}

.width-15 {
  width: 15;
}

.width-20 {
  width: 20;
}

.width-25 {
  width: 25;
}

.width-30 {
  width: 30;
}

.width-35 {
  width: 35;
}

.width-40 {
  width: 40;
}

.width-45 {
  width: 45;
}

.width-50 {
  width: 50;
}

.width-55 {
  width: 55;
}

.width-60 {
  width: 60;
}

.width-65 {
  width: 65;
}

.width-70 {
  width: 70;
}

.width-75 {
  width: 75;
}

.width-80 {
  width: 80;
}

.width-85 {
  width: 85;
}

.width-90 {
  width: 90;
}

.width-95 {
  width: 95;
}

.width-100 {
  width: 100;
}

.hls-d-flex {
  display: flex;
}

.hls-d-flex-2-0 {
  flex: 2 0;
}